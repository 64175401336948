import editorStrings from './editor.strings';

export enum ElementTags {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  COMPANY_LOGO = 'COMPANY_LOGO',
  EMPLOYEE_PHOTO = 'EMPLOYEE_PHOTO',
  ICON = 'ICON',
  SOCIAL_ICON = 'SOCIAL_ICON',
  SOCIAL_ICONS = 'SOCIAL_ICONS',
  FACEBOOK_ICON = 'FACEBOOK_ICON',
  INSTAGRAM_ICON = 'INSTAGRAM_ICON',
  LINKEDIN_ICON = 'LINKEDIN_ICON',
  TIKTOK_ICON = 'TIKTOK_ICON',
  X_ICON = 'X_ICON',
  YOUTUBE_ICON = 'YOUTUBE_ICON',
  DETAIL = 'DETAIL',
  DETAILS = 'DETAILS',
  NAME = 'NAME',
  PRONOUNS = 'PRONOUNS',
  COMPANY = 'COMPANY',
  TITLE = 'TITLE',
  EMAIL = 'EMAIL',
  ADDRESS = 'ADDRESS',
  WEBSITE = 'WEBSITE',
  PHONE = 'PHONE',
  FAX = 'FAX',
  MOBILE = 'MOBILE',
  EXTENSION = 'EXTENSION',
  DIVIDER = 'DIVIDER',
  QUOTE = 'QUOTE',
  HTML = 'HTML',
  PROMOTION = 'PROMOTION',
  GREEN_FOOTER = 'GREEN_FOOTER',
  DOWNLOAD_BUTTONS = 'DOWNLOAD_BUTTONS',
  DISCLAIMER = 'DISCLAIMER',
  SIGNOFF = 'SIGNOFF',
  YOUTUBE_VIDEO = 'YOUTUBE_VIDEO',
  IMAGE_GALLERY = 'IMAGE_GALLERY',
  INSTAGRAM_GALLERY = 'INSTAGRAM_GALLERY',
  BUTTON = 'BUTTON',
  ANIMATED_IMAGE = 'ANIMATED_IMAGE',
}

export const mapElements: { type: ElementTags; name: string }[] = [
  { type: ElementTags.TEXT, name: editorStrings.TEXT },
  { type: ElementTags.IMAGE, name: editorStrings.IMAGE },
  { type: ElementTags.COMPANY_LOGO, name: editorStrings.COMPANY_LOGO },
  { type: ElementTags.EMPLOYEE_PHOTO, name: editorStrings.EMPLOYEE_PHOTO },
  { type: ElementTags.ICON, name: editorStrings.ICON },
  { type: ElementTags.SOCIAL_ICON, name: editorStrings.SOCIAL_ICON },
  { type: ElementTags.SOCIAL_ICONS, name: editorStrings.SOCIAL_ICONS },
  { type: ElementTags.FACEBOOK_ICON, name: editorStrings.FACEBOOK_ICON },
  { type: ElementTags.INSTAGRAM_ICON, name: editorStrings.INSTAGRAM_ICON },
  { type: ElementTags.LINKEDIN_ICON, name: editorStrings.LINKEDIN_ICON },
  { type: ElementTags.TIKTOK_ICON, name: editorStrings.TIKTOK_ICON },
  { type: ElementTags.X_ICON, name: editorStrings.X_ICON },
  { type: ElementTags.YOUTUBE_ICON, name: editorStrings.YOUTUBE_ICON },
  { type: ElementTags.DETAIL, name: editorStrings.DETAIL },
  { type: ElementTags.DETAILS, name: editorStrings.DETAILS },
  { type: ElementTags.NAME, name: editorStrings.NAME },
  { type: ElementTags.PRONOUNS, name: editorStrings.PRONOUNS },
  { type: ElementTags.COMPANY, name: editorStrings.COMPANY },
  { type: ElementTags.TITLE, name: editorStrings.TITLE },
  { type: ElementTags.EMAIL, name: editorStrings.EMAIL },
  { type: ElementTags.ADDRESS, name: editorStrings.ADDRESS },
  { type: ElementTags.WEBSITE, name: editorStrings.WEBSITE },
  { type: ElementTags.PHONE, name: editorStrings.PHONE },
  { type: ElementTags.FAX, name: editorStrings.FAX },
  { type: ElementTags.MOBILE, name: editorStrings.MOBILE },
  { type: ElementTags.EXTENSION, name: editorStrings.EXTENSION },
  { type: ElementTags.DIVIDER, name: editorStrings.DIVIDER },
  { type: ElementTags.QUOTE, name: editorStrings.QUOTE },
  { type: ElementTags.HTML, name: editorStrings.HTML },
  { type: ElementTags.PROMOTION, name: editorStrings.PROMOTION },
  { type: ElementTags.GREEN_FOOTER, name: editorStrings.GREEN_FOOTER },
  { type: ElementTags.DOWNLOAD_BUTTONS, name: editorStrings.DOWNLOAD_BUTTONS },
  { type: ElementTags.DISCLAIMER, name: editorStrings.DISCLAIMER },
  { type: ElementTags.SIGNOFF, name: editorStrings.SIGNOFF },
  { type: ElementTags.YOUTUBE_VIDEO, name: editorStrings.YOUTUBE_VIDEO },
  { type: ElementTags.IMAGE_GALLERY, name: editorStrings.IMAGE_GALLERY },
  {
    type: ElementTags.INSTAGRAM_GALLERY,
    name: editorStrings.INSTAGRAM_GALLERY,
  },
  { type: ElementTags.BUTTON, name: editorStrings.BUTTON },
  { type: ElementTags.ANIMATED_IMAGE, name: editorStrings.ANIMATED_IMAGE },
];
