<script setup lang="ts">
// 3rd party
import { onMounted, onUnmounted, ref } from 'vue';
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';

// monorepo packages
import { Unsub } from '@mfl/framework';
import { WsButton } from '@mfl/common-components';
import { Template } from '@msl/signature-template-gateway-sdk';
import { headerComponents, showLogo } from '@mfe/core-header';

// local
import strings from './editor.strings';
import preview from './lib/editor-signature-preview.vue';
import ElementsList from './elements-list.vue';
import { createTemplate } from './editor-utils';
import editorHeaderWidget from './editor-header-widget.vue';

const selectedTemplate = ref<Template | undefined>(undefined);
let removeHeaderWidget: Unsub | undefined;
onMounted(() => {
  showLogo(false);

  removeHeaderWidget = headerComponents.register({
    key: 'editor-key',
    component: editorHeaderWidget,
    events: {
      change: (item: Template) => {
        selectedTemplate.value = item;
      },
    },
  });
});

onUnmounted(() => {
  if (removeHeaderWidget) removeHeaderWidget();
});

async function create() {
  const res = await createTemplate();
  if (res) {
    selectedTemplate.value = res;
  }
}

const stabPayload = {
  employee: {
    name: 'John Smith',
    title: 'First Mate',
    photo:
      'https://d36urhup7zbd7q.cloudfront.net/u/MwGeOew9BO9/95dac69e-1586-4ef7-8765-b955d957e402__400x400__.jpeg#logo2',
    email: 'johnsmith@gmail.com',
    mobile: '111-1111-111',
  },
  company: {
    name: 'Fin and Tonic',
    phone: '222-2222-222',
    website: 'https://finandtonic.com',
    logo: 'https://d36urhup7zbd7q.cloudfront.net/u/MwGeOew9BO9/492a5a83-243f-477d-83cb-d0359eb2e004__253x253__.png',
    address: '248 W 9240 S Sandy, UT 84070, Michigan, US',
    facebook_link: 'facebook.com',
    youtube_link: 'youtube.com',
    linkedin_link: 'linkedin.com',
    twitter_link: 'x.com',
    instagram_link: 'instagram.com',
    pinterest_link: 'https://www.pinterest.com/',
    tiktok_link: 'https://tiktok.com',
    banner: 'https://d36urhup7zbd7q.cloudfront.net/b/1668052172479.png',
  },
};
</script>

<template>
  <div class="ws-editor min-h-full">
    <div
      v-if="!selectedTemplate"
      class="flex flex-col justify-center items-center min-h-full"
    >
      <div class="text-2xl font-semibold">{{ strings.empty }}</div>

      <WsButton
        aid="CREATE_TEMPLATE_BUTTON"
        class="mt-6 self-center"
        color="primary"
        size="lg"
        :label="strings.createTemplate"
        @click="create"
      />
    </div>

    <div v-else class="grid grid-cols-[520px_calc(100%-520px)] min-h-full">
      <div class="flex min-h-full">
        <TabGroup vertical>
          <div class="w-[100px] border border-gray-100 py-6 px-3">
            <TabList class="flex flex-col justify-center gap-[24px]">
              <Tab>Icon</Tab>
              <Tab></Tab>
            </TabList>
          </div>
          <div class="py-6 w-[420px] text-lg">
            <TabPanels>
              <TabPanel>
                <ElementsList
                  :key="selectedTemplate.id"
                  :selected-template="selectedTemplate"
                />
              </TabPanel>
              <TabPanel> </TabPanel>
            </TabPanels>
          </div>
        </TabGroup>
      </div>
      <div class="grid grid-rows-[repeat(3,auto)_1fr] bg-gray-100">
        <preview
          :id="selectedTemplate?.id"
          :key="selectedTemplate?.id"
          :payload="stabPayload"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
// .ws-editor {

// }
</style>
