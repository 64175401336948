export default {
  empty: "You currently don't have any templates.",
  createTemplate: 'Create Template',
  name: 'Name',
  templateName: 'Template name',
  renameTemplate: 'Rename',
  deleteTemplate: 'Delete',
  cancel: 'Cancel',
  deleteTitle: 'Delete template',
  deleteQuestion:
    'Are you sure you want to delete this template? Once deleted this cannot be undone.',
  deleteButton: 'Delete',
  editHeader: 'Elements',
  actions: 'Actions',

  // Text for mappers
  TEXT: 'Text',
  IMAGE: 'Image',
  COMPANY_LOGO: 'Company Logo',
  EMPLOYEE_PHOTO: 'Employee Photo',
  ICON: 'Icon',
  SOCIAL_ICON: 'Social Icon',
  SOCIAL_ICONS: 'Social Icons',
  FACEBOOK_ICON: 'Facebook Icon',
  INSTAGRAM_ICON: 'Instagram Icon',
  LINKEDIN_ICON: 'Linkedin Icon',
  TIKTOK_ICON: 'Tiktok Icon',
  X_ICON: 'X Icon',
  YOUTUBE_ICON: 'Youtube Icon',
  DETAIL: 'Detail',
  DETAILS: 'Details',
  NAME: 'Name',
  PRONOUNS: 'Pronouns',
  COMPANY: 'Company',
  TITLE: 'Title',
  EMAIL: 'Email',
  ADDRESS: 'Address',
  WEBSITE: 'Website',
  PHONE: 'Phone',
  FAX: 'Fax',
  MOBILE: 'Mobile',
  EXTENSION: 'Extension',
  DIVIDER: 'Divider',
  QUOTE: 'Quote',
  HTML: 'Html',
  PROMOTION: 'Promotion',
  GREEN_FOOTER: 'Green Footer',
  DOWNLOAD_BUTTONS: 'Download Buttons',
  DISCLAIMER: 'Disclaimer',
  SIGNOFF: 'Signoff',
  YOUTUBE_VIDEO: 'Youtube Video',
  IMAGE_GALLERY: 'Image Gallery',
  INSTAGRAM_GALLERY: 'Instagram Gallery',
  BUTTON: 'Button',
  ANIMATED_IMAGE: 'Animated Image',
};
