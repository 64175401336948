import { prompt, PromptResult } from '@mfl/common-components';
import strings from '../editor.strings';

export type DeleteTemplatePromptInput = {
  id: string;
};

export async function openDeleteTemplatePrompt(): Promise<
  PromptResult | undefined
> {
  return await prompt({
    aid: 'DELETE_TEMPLATE_PROMPT',
    header: `${strings.deleteTitle}`,
    question: `${strings.deleteQuestion}`,
    primaryButtonText: `${strings.deleteButton}`,
    secondaryButtonText: `${strings.cancel}`,
    primaryButtonColor: 'danger',
    width: '400px',
  });
}
