import { Template } from '@msl/signature-template-gateway-sdk';
import { openDialog } from '@mfl/common-components';

import strings from '../editor.strings';
import RenameTemplateDialog from './rename-template-dialog.vue';

export type RenameTemplateDialogInput = {
  template: Template;
};

export type RenameTemplateDialogOutput = {
  template?: Template;
};

export async function openRenameTemplateDialog(
  input: RenameTemplateDialogInput
): Promise<RenameTemplateDialogOutput | undefined> {
  return await openDialog<
    RenameTemplateDialogInput,
    RenameTemplateDialogOutput
  >({
    aid: 'RENAME_TEMPLATE_DIALOG',
    headerText: `${strings.templateName}`,
    component: RenameTemplateDialog,
    input,
  });
}
