<script setup lang="ts">
// 3rd party

// monorepo packages

// local

type Props = {
  elementName?: string;
};

const props = withDefaults(defineProps<Props>(), {
  elementName: '',
});

const emit = defineEmits(['close']);
</script>

<template>
  <div class="full-width">
    <div class="flex justify-between border-b border-gray-50 mb-[14px]">
      <div class="text-bold pb-[14px]">{{ props.elementName }}</div>
      <div>
        <a href="#" class="color-gray-400" @click="emit('close')"
          ><span class="fa-close fa-light"></span
        ></a>
      </div>
    </div>
    <slot />
  </div>
</template>

<style scoped></style>
