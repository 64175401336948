import { Template } from '@msl/signature-template-gateway-sdk';
import { openDialog } from '@mfl/common-components';

import strings from '../editor.strings';
import CreateTemplateDialog from './create-template-dialog.vue';

export type CreateTemplateDialogInput = {
  template: Template;
};

export type CreateTemplateDialogOutput = {
  template?: Template;
};

export async function openCreateTemplateDialog(
  input: CreateTemplateDialogInput
): Promise<CreateTemplateDialogOutput | undefined> {
  return await openDialog<
    CreateTemplateDialogInput,
    CreateTemplateDialogOutput
  >({
    aid: 'CREATE_TEMPLATE_DIALOG',
    headerText: `${strings.templateName}`,
    component: CreateTemplateDialog,
    input,
  });
}
