<script setup lang="ts">
// 3rd party
import { computed, ref } from 'vue';

// monorepo packages

// local
import { mapElements, ElementTags } from './mappers';
import ElementContainer from './elements/element-container.vue';
import NameEdit from './elements/name-edit.vue';
import strings from './editor.strings';
import { ElementListProps } from './elements-list';

const props = withDefaults(defineProps<ElementListProps>(), {
  selectedTemplate: undefined,
});

const editMode = ref<boolean>(false);
const tagName = ref<string>('');

const elements = computed(() => {
  return (
    props.selectedTemplate?.layout.elements.filter((element) =>
      mapElements.some((ele) => ele.type === element.tag)
    ) ?? []
  );
});

function fetchElementName(element: string) {
  const currentElement = mapElements.find(
    (ele: Record<string, string>) => ele.type === element
  );
  return currentElement?.name;
}

function editElement(tag: string) {
  editMode.value = true;
  tagName.value = tag;
}
</script>

<template>
  <div v-if="!editMode">
    <div class="text-bold pb-[14px] border-b border-gray-50 px-6">
      {{ strings.editHeader }}
    </div>
    <a
      v-for="e of elements"
      :key="e.tag"
      href="#"
      aid="TEMPLATE_ELEMENT_LIST_ITEMS"
      class="flex justify-between items-center py-[20px] px-6 hover:bg-gray-100 ws_editor__element-list-items relative text-gray-500"
      @click="editElement(e.tag)"
    >
      {{ fetchElementName(e.tag) }}
      <span class="fa-chevron-right fa-light"></span>
    </a>
  </div>
  <div v-else>
    <ElementContainer
      :element-name="fetchElementName(tagName)"
      @close="editMode = false"
    >
      <NameEdit v-if="tagName === ElementTags.NAME" />
    </ElementContainer>
  </div>
</template>

<style scoped lang="scss">
.ws_editor__element-list-items:after {
  content: '';
  position: absolute;
  bottom: 0;
  border-bottom: 1px solid;
  left: 24px;
  right: 24px;
  border-color: rgb(var(--color-gray-100));
}
</style>
