import {
  BaseLayout,
  signatureTemplateGateway,
  Template,
} from '@msl/signature-template-gateway-sdk';

import { openCreateTemplateDialog } from './dialogs/create-template-dialog';
import { openRenameTemplateDialog } from './dialogs/rename-template-dialog';
import { openDeleteTemplatePrompt } from './dialogs/delete-template-prompt';
import data from './assets/default-layout.json';

export async function createTemplate(): Promise<Template | null> {
  const newTemplate: Template = {
    name: '',
    layout: data as unknown as BaseLayout,
    accountId: '',
  };
  const result = await openCreateTemplateDialog({ template: newTemplate });

  if (!result?.template?.id) {
    console.error('Template was not created');
    return null;
  }

  return await signatureTemplateGateway.getOne({
    id: result.template.id,
  });
}

export async function updateTemplate(
  id: string,
  templates: Template[] = []
): Promise<string> {
  const editTemplate: Template = templates.filter((t) => t.id === id)[0];
  const result = await openRenameTemplateDialog({ template: editTemplate });

  if (!result || !result.template) return '';

  return result.template.name;
}

export async function deleteTemplate(
  id: string = '',
  templates: Template[] = []
): Promise<boolean> {
  const result = await openDeleteTemplatePrompt();

  if (result && result === 'Primary') {
    const t: Template = templates.filter((t) => t.id === id)[0];
    const res = await signatureTemplateGateway.delete({ id: t.id || '' });

    return res?.success;
  }

  return false;
}
