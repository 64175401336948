<script setup lang="ts">
import { ref } from 'vue';

import { IDialog } from '@mfl/common-components/src';
import { signatureTemplateGateway } from '@msl/signature-template-gateway-sdk';
import {
  WsDialogForm,
  WsDialogSection,
  WsInput,
  WsDialogActions,
  WsButton,
} from '@mfl/common-components';

import type {
  RenameTemplateDialogInput,
  RenameTemplateDialogOutput,
} from './rename-template-dialog';
import strings from '../editor.strings';

const { dialog } = defineProps<{
  dialog: IDialog<RenameTemplateDialogInput, RenameTemplateDialogOutput>;
}>();

const busy = ref(false);
const templateName = ref<string>(dialog.input.template.name);

async function save() {
  if (templateName.value === '') return;

  busy.value = true;

  const { template } = dialog.input;
  template.name = templateName.value;

  try {
    await signatureTemplateGateway.update({ template });
  } catch {
    // Alert for error
    return;
  }

  closeMe();

  busy.value = false;
}

function closeMe() {
  dialog.close();
}
</script>

<template>
  <WsDialogForm class="w-[400px]" @submit="save">
    <WsDialogSection>
      <span>{{ strings.name }}</span>
      <WsInput
        v-model="templateName"
        size="md"
        aid="EDITOR_DIALOG_RENAME_TEMPLATE_INPUT"
        :label="strings.templateName"
      />
    </WsDialogSection>

    <WsDialogActions>
      <WsButton
        :label="strings.cancel"
        variant="outlined"
        aid="EDITOR_DIALOG_RENAME_TEMPLATE_CANCEL"
        @click="closeMe()"
      />
      <WsButton
        type="submit"
        label="save"
        aid="EDITOR_DIALOG_RENAME_TEMPLATE_SAVE"
        :disabled="templateName.trim() === ''"
        :loading="busy"
      />
    </WsDialogActions>
  </WsDialogForm>
</template>
